import { useState, useEffect, useReducer, useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

export const getAPIUrl = () => {
    return process.env.REACT_APP_API_URI;
}

const dataFetchReducer = (state:any, action:any) => {
    switch (action.type) {
        case 'FETCH_INIT':
            return {
                ...state,
                isLoading: true,
                isError: false
            };
        case 'FETCH_SUCCESS':
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: action.payload,
            };
        case 'FETCH_FAILURE':
            return {
                ...state,
                isLoading: false,
                isError: true, 
            };
        case 'DATA_UPDATE':
            return {
                ...state,
                data: action.payload
            }
        default:
            throw new Error();
    }
};

const convertDataToParams = (dataPre: any) => {
    let data = JSON.parse(JSON.stringify(dataPre)); //> prevents changes to original data
    let getParams= '?';
    Object.keys(data).forEach((e) => {

        if(!!data[e] && typeof data[e].getMonth === 'function'){
            data[e] = (data[e].getMonth() + 1) + "/" + data[e].getDate() + "/" + data[e].getFullYear();
        }

        if(!!data[e] && Array.isArray(data[e])){
            let arrTxt = '';
            data[e].forEach((entry:any, i: number) => {
                arrTxt += (i === 0 ? '' : e + "=") + entry + "&";
            });

            data[e] = arrTxt.substring(0, arrTxt.length - 1);
        }

        getParams += e + '=' + data[e] + '&'
    })

    return getParams.substring(0, getParams.length -1);
} 

export interface fetchRequest<T> {
    url?: string,
    data?: object,
    config?: object,
    method?: 'get' | 'post' | 'put'| 'delete',
    callback?: Function
}

export function useDataApi<T extends any> (initialRequest?: fetchRequest<T> | null | string, initialData?: any) {

    const [request, setRequest] = useState<fetchRequest<T> | string | undefined | null>(initialRequest);
    const [state, dispatch] = useReducer(dataFetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
    });

    const preSetRequest = useCallback((requestNew?: fetchRequest<T> | null | string) => {
        if( JSON.stringify(request) !== JSON.stringify(requestNew)){
            setRequest(requestNew);
        }
    }, [request]);

    const baseAPISrc = getAPIUrl();

    const hitApi = useCallback(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();

        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT' });

            try {
                let axiosRequest;
                if(typeof request === 'string'){
                    axiosRequest = {url: baseAPISrc + request, method: 'get'}
                }
                else  {
                    axiosRequest = {...request, url: (!!request && !!baseAPISrc ? baseAPISrc + request.url: '')};
                }

                if((!axiosRequest.method || axiosRequest.method === 'get') && !!axiosRequest.data){
                    axiosRequest.url += convertDataToParams(axiosRequest.data);
                }

                let config:any = {};
                if(!!axiosRequest.callback){
                    config = {...axiosRequest.config};
                    delete axiosRequest.config;
                }

                let configHeaders = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                };

                if(!!config.headers){
                    configHeaders = {
                        ...config.headers, 
                        ...configHeaders
                    }
                }

                delete config.headers;
             
                axiosRequest = {
                    ...axiosRequest, 
                    withCredentials: true,
                    cancelToken: source.token,
                    headers: configHeaders,
                    ...config
                };

                let callback: Function | null = null;
                if(!!axiosRequest.callback){
                    callback = axiosRequest.callback.bind({});
                    delete axiosRequest.callback;
                }
                 
                let result = await axios(axiosRequest as AxiosRequestConfig);
                if (!axios.isCancel(result)) {
                    if(!!callback){
                        //checking response removes the need to return response in callback if untouched
                        const callbackResponse = callback(result, state);
                        if(!!callbackResponse){
                            result = callbackResponse;
                        }
                    }
                    
                    dispatch({ type: 'FETCH_SUCCESS', payload: result.data });
                }
            } catch (error) {
                if (!axios.isCancel(error)) {
                    dispatch({ type: 'FETCH_FAILURE' });
                }
            }
        };

        if(!!request && (typeof request === 'string' || !!request.url)){
            fetchData();
        }

        return () => {
            source.cancel();
        };
    }, [request]) // eslint-disable-line react-hooks/exhaustive-deps  
    ///> weaning disabled because if dependecies are included, api calls will all rerun when token renewed 

    useEffect(() => {
            hitApi(); 
    }, [hitApi]); 

    const setData = (data) => {
        dispatch({ type: 'DATA_UPDATE', payload: data });
    }

    return [state, preSetRequest, setData];
};