import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";
import { TROThemeProvider } from './Components/Context/ThemeContext';
import { AuthContext } from './Components/Context/Auth';
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(
    <React.StrictMode>
        <CookiesProvider>
        <AuthContext.Provider value={false}>
        <TROThemeProvider>
            <Router>
                <App />
            </Router>
        </TROThemeProvider>
        </AuthContext.Provider>
        </CookiesProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
