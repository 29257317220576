import React, { useState, useEffect } from "react";
import { makeStyles, TextField, InputAdornment, LinearProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ForwardOutlinedIcon from '@material-ui/icons/ForwardOutlined';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import { useDataApi } from "src/Components/Hooks/UseDataApi";
import useEventListener from '@use-it/event-listener';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
    root: { 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 100px)'
    },
    logo: {
        width: 200,
        margin: 30
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    input: {
        margin: 10,
        width: 200,
        '& input': {
        textAlign: 'left'
        }
    },
    inputIcon: {
        color: theme.palette.primary.main
    },
    loginBtn: {
        marginLeft: 180,
        fontSize: '23pt',
        marginTop: 10,
        cursor: 'pointer',
        '&:hover': {
            opacity: '0.7'
        }
    },
    errorTxt: {
        color: 'red',
        padding: 0,
        margin: 0,
        width: 220,
        opacity: 0,
        maxHeight: '0px',
        transition: 'all 0.5s',
        overflow: 'hidden',
        fontStyle: 'italic',
        fontWeight: 100,
        fontSize: '10pt',
        textAlign: 'center'
    }
}));
 
const Login: React.FC = () => {

    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [returnUrl, setReturnUrl] = useState("");
    const [callback, setCallback] = useState("");
    const [error, setError] = useState("");
    const [response, doFetch] = useDataApi(null, { response: "" });
    const [loginErrorTxt, setLoginErrorTxt] = useState("");
    const [cookie, setRedirectCookie, removeRedirectCookie] = useCookies(['redirecturl']);

    useEventListener('keydown', e => {
        let keyEvent = e as unknown as KeyboardEvent;
        if ("Enter" === keyEvent.key) {
            execLogin();
        }
    });

    useEffect(() => {
        const query = window.location.search.substring(1);
        const vars = query.split('&');
        let returnUrlParam, errorUrlParam, codeParmam;
        for (let i = 0; i < vars.length; i++) {
            let pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === 'ReturnUrl') {
                returnUrlParam = decodeURIComponent(pair[1]);
            }
            else if (decodeURIComponent(pair[0]) === 'errorId') {
                errorUrlParam = decodeURIComponent(pair[1]);
            }
            else if(decodeURIComponent(pair[0]) === 'code'){
                //if code is passed, redirect required to url in cookie (IIS Bug) https://stackoverflow.com/questions/51477539/no-redirect-back-to-jsoidc-client-when-identityserver4-behind-iis-reverse-proxy
                codeParmam = decodeURIComponent(pair[1]);
            }
        }

        if(!!returnUrlParam){
            setReturnUrl(returnUrlParam);

            //find callback for redirect if IIS fails 
            let callback = decodeURIComponent(returnUrlParam.split("redirect_uri=").pop().split("callback")[0]);
            setRedirectCookie('redirecturl', callback, {path: '/'});
        }
        else if(!!errorUrlParam){
            setError(errorUrlParam);
        }
        else if(!!codeParmam){
            window.location.href = cookie.redirecturl + "callback/" + window.location.search;
        }
    }, []);

    useEffect(() => {
        if (!!response.data && !!response.data.isOk) { 
            window.location = response.data.redirectUrl;
        }
        else if(!response.data.isOk && !!response.data.response)
        {
            setLoginErrorTxt(response.data.response);
        }

    }, [response]);

    const execLogin = () => {
        doFetch({
            url: "authenticate",
            method: 'post',
            data: {
                username,
                password,
                returnUrl
            }
        });
    }

    if(!setReturnUrl){
        return <LinearProgress /> 
    }

    return (
        <div className={classes.root}>

            <img src={"/Images/logo.png"} className={classes.logo}/>
            <form className={classes.form}>
                <TextField 
                    className={classes.input}
                    required 
                    onChange={(e) => {setUsername(e.target.value)}}
                    value={username}
                    id="login-uname" 
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleIcon className={classes.inputIcon} />
                          </InputAdornment>
                        )
                    }}
                />

                <TextField
                    className={classes.input}
                    required
                    onChange={(e) => {setPassword(e.target.value)}}
                    value={password}
                    id="login-password"
                    type="password"
                    autoComplete="current-password"
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <VpnKeyIcon className={classes.inputIcon} />
                          </InputAdornment>
                        )
                    }}
                />
                <PlayArrowOutlinedIcon 
                    className={classes.loginBtn}
                    onClick={execLogin}/>
            </form>
            <p 
                className={classes.errorTxt}
                style={loginErrorTxt === "" ? {maxHeight: '0px', opacity: 0, padding: 0} : {maxHeight: '100px', opacity: 0.8, padding: 10}}
            >
                {loginErrorTxt}
            </p>
        </div>
    );
}

export default Login