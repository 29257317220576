import { blue } from "@material-ui/core/colors";
import { CustomTheme } from "./types";

import './fonts.css'

export const CustomThemeTemplate: CustomTheme = {
    palette: {
        primary: { main: '#292f3e' }, 
        secondary: blue
    },
    dialog: {
        minWidth: 300
    },
    typography: {
        fontFamily: [
            'Homeday',
        ].join(',')
    }
};