import React, { useState, useEffect } from "react";
import { useDataApi } from "src/Components/Hooks/UseDataApi";

const Logout: React.FC = () => {

    const [description, setDescription] = useState("");
    var query = window.location.search;
    var logoutIdQuery = query && query.toLowerCase().indexOf('?logoutid=') === 0 && query;
    const [logoutResponse] = useDataApi('authenticate/logout' + logoutIdQuery);

    useEffect(() => {

        if(!!logoutResponse.data){
            const data = logoutResponse.data;
            if (data.signOutIFrameUrl) {
                var iframe = document.createElement('iframe');
                iframe.width = "0px";
                iframe.height = "0px";
                iframe.className = 'signout';
                iframe.src = data.signOutIFrameUrl;
                var logoutIframe = document.getElementById('logout_iframe');
                if(!!logoutIframe){
                    logoutIframe.appendChild(iframe);
                }
            }
            if (data.postLogoutRedirectUri) {
                window.location = data.postLogoutRedirectUri;
            } else {
                setDescription("You can close this window. Bye!");
            }
        }
    }, [logoutResponse])
   
    return (
        <div>
            <br/><br/><br/>
            ...logging out

            <div id="logout_iframe"></div>
            <p>{description}</p>

        </div>
    );
}

export default Logout