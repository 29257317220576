import React, { createContext, useReducer, ReactElement } from 'react';
import { CustomTheme } from 'src/types';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';
import { CustomThemeTemplate } from 'src/typesTemplates';

const initialState: any = CustomThemeTemplate;
const ThemeContext = createContext(initialState);

const { Provider } = ThemeContext;

const TROThemeProvider = (props: { children: ReactElement }) => {

    const [themeState, themeDispatch] = useReducer((state: CustomTheme, action: any) => {
        switch (action.type) {
            case "retrieve":
                //hit api to retrieve user preference
                return initialState;
            case "set":
                return action.payload;
            case "edit-palette":
                console.log( { ...state, palette: {...state.palette, ...action.payload} });
                return { ...state, palette: {...state.palette, ...action.payload} };
            case "edit": 
                return { ...state, ...action.payload };
            case "reset":
                return initialState;
            //could have other defaults - e.g dark theme 
            default:
                throw new Error();
        }
    }, initialState);

  

    let themeObj = { 
        ...themeState,
        typography: {
            fontFamily: 'Poppins, Arial',
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                },
            },
        },
    }

    return (
        <Provider value={{ themeState, themeDispatch }}>
            <ThemeProvider theme={createMuiTheme(themeObj)}>
                {props.children}
            </ThemeProvider>
        </Provider>
    );
};

export {
    ThemeContext,
    TROThemeProvider
}