import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Login from 'src/Screens/Login/Login';
import Logout from 'src/Screens/Login/Logout';

const Main: React.FC = (baseProps) => {

    return (
        <main>
            <Switch>
                <Route path="/logout" render={routeProps => <Logout />}/>
                <Route path="/" render={routeProps => <Login />}/>
            </Switch>
        </main>
    ); 
};

export default Main