import React, { useState, createContext } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';

import './App.css';
import { MediaQuery } from './types';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PageBase from './Components/PageBase/PageBase';
import Main from './Components/PageBase/Main';

const useStyles = makeStyles(theme => ({
    root: {
        height: "calc(100% - 120px)", ///> - 100 for padding + app bar height - should find how to retrieve as can change
        padding: 10,
        paddingTop: 30
    },
    appContent: {
        height: "100%",
        marginTop: 68,
        padding: 8
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: { 
        
    },
    searchContainer: {
        marginLeft: 25,
        marginRight: 25,
        height: '100%',
        maxWidth: 300,
        borderRadius: 50,
        background: '#f4f4e845'
    },
    search: {
        marginLeft: 20,
        marginTop: -13,
        maxWidth: 300,
        marginRight: 10,
        '& .MuiInput-underline:before ': {
            borderColor: '#4e4e4e',
            borderBottomStyle: 'dotted',
        },
        '& .MuiInput-underline:after ': { 
            borderColor: 'grey',
        }
    },
    searchInput: {
        color: 'white !important'
    },
    searchInputLabel: {
        display: 'none',
        color: 'transparent'
    },
    offset: {
        ...theme.mixins.toolbar,
        flexGrow: 1
    },
    main: {

    }
}));

export const LoadingDispatch = createContext({});
export const MediaQueryContext = createContext({});


const App: React.FC = () => {
    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = useState(false);

    var mediaQuery: MediaQuery = {
        l: useMediaQuery((theme: any) => theme.breakpoints.down('lg')),
        m: useMediaQuery((theme: any) => theme.breakpoints.down('md')),
        s: useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
    }

    window.addEventListener('resize', () => { setDrawerOpen(!mediaQuery.l) });

    const toggleDrawer = (open: boolean) => (event: any) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setDrawerOpen(open);
    };

    return (
        <div className={classes.root}>
            <PageBase>
                <AppBar position="fixed">
                    <Toolbar>
                        <IconButton onClick={toggleDrawer(!drawerOpen)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            {mediaQuery.l ? <MenuIcon /> : drawerOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>TRO</Typography>
                       
                    </Toolbar>
                </AppBar>
                <Main />
            </PageBase>
        </div>
    );
}

export default withRouter(App);
